import React, { useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import logo from '../../images/logo.jpg'

export default function Navbar() {
  const [service, setService] = useState({ display: "none",transition: "0.2s all ease" });
  const [cpkv, setCpkv] = useState(true);
  const [icon, setIcon] = useState({transform: "rotate(0deg)", transition: "0.2s all ease"})

  const afficherService = () => {
    if (cpkv) {
      setIcon({transform: "rotate(180deg)",transition: "0.2s all ease"})
      setService({ display: "block", transition: "0.2s all ease" });
      setCpkv(false);
    } else {
      setIcon({transform: "rotate(0deg)", transition: "0.2s all ease"})
      setService({ display: "none", transition: "0.2s all ease" });
      setCpkv(true);
    }
  };
  const style = {
    textDecoration: "none",
    color: "white",
    display: "flex",
    alignItems: "center",
  };
  return (
    <>
      <div className="navbar">
        <div className="logo">
          <img src={logo} alt="logo" width="70" height="70" />
        </div>
        <div className="menu">
          <ul>
            <li>
              <Link to="/" style={style}>
                Acceuil
              </Link>
            </li>
            <li onClick={afficherService}>
              <Link style={style}>
                <span>Services</span>
                <ExpandMoreIcon style={icon} />
              </Link>
            </li>
            <li>
              <Link style={style}>Qui sommes-nous ?</Link>
            </li>
          </ul>
        </div>
        <div className="bouton">
          <Link style={style}>
            <button>Newsletter</button>
          </Link>
        </div>
      </div>
      <div className="service" style={service}>
        <ul
          style={{
            display: "flex",
            listStyle: "none",
            justifyContent: "space-evenly",
            backgroundColor: "gray",
            color: "rgba(255, 255, 255, 0.665)",
            padding: "1rem",
            borderTop: "1px solid rgba(255, 255, 255, 0.665)",
          }}
        >
          <li>Acheter</li>
          <li>Louer</li>
          <li>Vendre</li>
          <li>Acheter</li>
          <li>Louer</li>
          <li>Vendre</li>
        </ul>
      </div>
    </>
  );
}
