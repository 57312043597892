import React from 'react'
import './publication.css'
import PlaceIcon from '@mui/icons-material/Place';
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';

export default function Publication(props) {

  return (
    <div className="publication">
        <h3><span>{props.type}</span><p><StarIcon style={{color: "yellow",fontSize: "20px"}}/><StarIcon style={{color: "white",fontSize: "18px"}}/><StarIcon style={{color: "white",fontSize: "18px"}}/></p></h3>
        <p>{props.statut}</p>
        <Link to={props.id + "/"}><img src={props.image} alt={"Image de " + props.type} width="200" height="200" /></Link> 
        <p style={{display: "flex", alignItems: "center"}}><PlaceIcon/> {props.lieu}</p>
        <p>Publié le <i>{props.date}</i></p>
    </div>
  )
}
