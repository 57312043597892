import React from 'react'
import './footer.css'
import { Facebook, LinkedIn, WhatsApp } from '@mui/icons-material'


export default function Footer() {
  return (
    <div className='footer'>
      <div className="naviguation">
        <div>
          <ul>
            <li><span>&#x3E;</span>Liste</li>
            <li><span>&#x3E;</span>Liste</li>
            <li><span>&#x3E;</span>Liste</li>
            <li><span>&#x3E;</span>Liste</li>
            <li><span>&#x3E;</span>Liste</li>
          </ul>
        </div>
        <div>
          <ul>
              <li><span>&#x3E;</span>Liste</li>
              <li><span>&#x3E;</span>Liste</li>
              <li><span>&#x3E;</span>Liste</li>
              <li><span>&#x3E;</span>Liste</li>
              <li><span>&#x3E;</span>Liste</li>
          </ul>
        </div>
        <div className='social'>
          <Facebook/>
          <LinkedIn/>
          <WhatsApp/>
        </div>
        <div className="newsletter">
          <h3>Newsletter</h3>
          <input type="email" />
        </div>
      </div>
      <div className="copyright">&copy;Devign Immobilier All right researved</div>
    </div>
  )
}
