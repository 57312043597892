import maison from './images/maison.jpg'
import immeuble from './images/immeuble.jpg'
import terrain from './images/terrain.jpg'
import studio from './images/studio.jpg'
import cuisine from './images/cuisine.jpg'
import chambre from './images/chambre.jpg'
import terrasse from './images/terrasse.jpg'
import toilette from './images/toilette.jpg'
const descr = "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minus ipsam earum ullam dolorem ea, sint explicabo, fugit error ex non mollitia ducimus adipisci fuga deserunt iusto blanditiis iure reprehenderit eveniet quae quis. Vel laborum itaque molestiae ab voluptas veritatis illo totam, sapiente incidunt esse atque, optio accusamus minus officiis rerum!"
const publications = [
    {id: 0, type: "Maison", image: maison, pieces:3, date: "19-02-2023", lieu: "Abengourou", statut: "A louer", prix: "100 000 Fcfa/mois", description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 1, type: "Immeuble", image: immeuble,pieces:15, date: "15-01-2023", lieu: "Bingerville" ,statut: "A vendre",prix: "100 000 000 Fcfa",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 2, type: "Terrain", image: terrain,pieces: "", date: "09-02-2015", lieu: "Assinie",statut: "A vendre", prix: "1 000 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 3, type: "Maison", image: maison, pieces:5,date: "19-02-2023", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 4, type: "Immeuble", image: immeuble,pieces:30, date: "15-01-2023", lieu: "Bingerville",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 5, type: "Terrain", image: terrain, pieces:"", date: "09-02-2015", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 6, type: "Maison", image: maison,pieces:7, date: "19-02-2023", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 7, type: "Immeuble", image: immeuble,pieces:56, date: "15-01-2023", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 8, type: "Terrain", image: terrain,pieces:"", date: "09-02-2015", lieu: "Assinie",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 9, type: "Studio", image: studio,pieces:1, date: "09-02-2015", lieu: "Assinie",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [studio,cuisine,chambre,terrasse,toilette]},
    {id: 10, type: "Terrain", image: terrain,pieces:"", date: "09-02-2015", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 11, type: "Maison", image: maison, pieces:3,date: "19-02-2023", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 12, type: "Immeuble", image: immeuble,pieces:54, date: "15-01-2023", lieu: "Bingerville",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 13, type: "Terrain", image: terrain, pieces: "", date: "09-02-2015", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 14, type: "Studio", image: studio, pieces:1, date: "09-02-2015", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [studio,cuisine,chambre,terrasse,toilette]},
    {id: 15, type: "Maison", image: maison, pieces:3, date: "19-02-2023", lieu: "Abengourou", statut: "A louer", prix: "100 000 Fcfa/mois", description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 16, type: "Immeuble", image: immeuble,pieces:15, date: "15-01-2023", lieu: "Bingerville" ,statut: "A vendre",prix: "100 000 000 Fcfa",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 17, type: "Terrain", image: terrain,pieces: "", date: "09-02-2015", lieu: "Assinie",statut: "A vendre", prix: "1 000 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 18, type: "Maison", image: maison, pieces:5,date: "19-02-2023", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 19, type: "Immeuble", image: immeuble,pieces:30, date: "15-01-2023", lieu: "Bingerville",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 20, type: "Terrain", image: terrain, pieces:"", date: "09-02-2015", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 21, type: "Maison", image: maison,pieces:7, date: "19-02-2023", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 22, type: "Immeuble", image: immeuble,pieces:56, date: "15-01-2023", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 23, type: "Terrain", image: terrain,pieces:"", date: "09-02-2015", lieu: "Assinie",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 24, type: "Studio", image: studio,pieces:1, date: "09-02-2015", lieu: "Assinie",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [studio,cuisine,chambre,terrasse,toilette]},
    {id: 25, type: "Terrain", image: terrain,pieces:"", date: "09-02-2015", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 26, type: "Maison", image: maison, pieces:3,date: "19-02-2023", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [maison,cuisine,chambre,terrasse,toilette]},
    {id: 27, type: "Immeuble", image: immeuble,pieces:54, date: "15-01-2023", lieu: "Bingerville",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [immeuble,cuisine,chambre,terrasse,toilette]},
    {id: 28, type: "Terrain", image: terrain, pieces: "", date: "09-02-2015", lieu: "Assinie",statut: "A vendre",prix: "100 000 Fcfa/mois",description: descr, images: [terrain,cuisine,chambre,terrasse,toilette]},
    {id: 29, type: "Studio", image: studio, pieces:1, date: "09-02-2015", lieu: "Abengourou",statut: "A louer",prix: "100 000 Fcfa/mois",description: descr, images: [studio,cuisine,chambre,terrasse,toilette]},
]

export default publications