import React, { useState } from 'react'
import './detail.css'
import Navbar from '../../composants/navbar/Navbar'
import Footer from '../../composants/footer/Footer'
import StarIcon from '@mui/icons-material/Star';
import PlaceIcon from '@mui/icons-material/Place';
import { useParams, Link } from 'react-router-dom';
import publications from '../../publicationdata'


export default function Details() {

  const {bienId}  = useParams();

  const [image, setImage] = useState(publications[bienId].image);

  return (
    <div className='details'>
      <Navbar/>
      <div className="detail">
        <div className='detail-img'>
          <div className="detail-image">
            <img src={image} width="630" height="400" alt="" />
          </div>
          <div className="detail-images">
            {publications[bienId].images.map((img)=>{
              return <img src={img} width="150" height="100"  alt="" onClick={()=>{setImage(img)}}/>
            })}
          </div>
        </div>
        <div className='detail-text'>
          <h1>{publications[bienId].type}</h1>
          <p>{publications[bienId].statut}</p>
          <p className='localisation'><PlaceIcon/><span>{publications[bienId].lieu}</span></p>
          <p><StarIcon style={{color: "yellow"}} /><StarIcon style={{color: "yellow"}} /><StarIcon style={{color: "yellow"}} /><StarIcon style={{color: "yellow"}} /><StarIcon style={{color: "white"}} /></p>
          <p>prix : {publications[bienId].prix}</p>
          <p>
            {publications[bienId].description}
          </p>
          <p className='boutton'><Link to={"reservation/"+ bienId} style={{textDecoration: "none", color: "white"}}>Reservez une visite</Link></p>
        </div>
      </div>
      <Footer/>
    </div>
  )
}
