import './App.css';
import Acceuil from './pages/acceuil/Acceuil';
import Details from './pages/details/Details';
import {BrowserRouter, Routes, Route } from 'react-router-dom'
import Reservation from './pages/reservation/Reservation';
import PageNotFound from './pages/pagenotfound/PageNotFound';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<Acceuil/>}/>
            <Route path=":bienId">
              <Route index  element={<Details/>}/>
              <Route path="reservation/:bienId" element={<Reservation/>}/>
            </Route>
          </Route>
          <Route element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
