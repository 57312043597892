import React from 'react'
import Footer from '../../composants/footer/Footer'
import Navbar from '../../composants/navbar/Navbar'

export default function PageNotFound() {
  return (
    <div>
        <Navbar/>
        <section>
            <h2>- Page not found</h2>
            <p>The page you are looking for does not exist.</p>
            <a href="/">Go to Home Page</a>
        </section>
        <Footer/>
    </div>
  )
}
