import React, { useEffect, useState } from 'react'
import './reservation.css'
import Navbar from '../../composants/navbar/Navbar'
import Footer from '../../composants/footer/Footer'
import reservation from '../../images/reservation.jpg'
import { useParams } from 'react-router'
import publications from '../../publicationdata'
import Loader from '../../pages/loader/Loader'

export default function Reservation() {
  const {bienId}  = useParams();

  const [loader, setLoader] = useState(true)

  useEffect(()=>{
    setTimeout(() => {
      setLoader(false)
    }, 1000);
  },[])

  return loader ? (
    <Loader/>
  ) : (
    <div className='reservation'>
      <Navbar/>
      <section className='banniere'>
        <div className="layer">
          <p>Remplissez ce formulaire ci dessous pour prendre rendez-vous pour une visite</p>
          <a href="#formulaire">Aller au formulaire</a>
        </div>
        <img src={reservation} alt="Reservation" width="80%" height="400" />
      </section>
      <section className="formulaire" id="formulaire">
        <h1 style={{textAlign: "center"}}>Formulaire de réservation de visite</h1>
        <form>
          <div>
          <table className='infoPerso'>
              <tr>
                <td>Nom</td>
                <td><input type="text" required/></td>
              </tr>
              <tr>
                <td>Prenom&#40;s&#41;</td>
                <td><input type="text" required/></td>
              </tr>
              <tr>
                <td>Lieu d'habitation</td>
                <td><input type="text"/></td>
              </tr>
              <tr>
                <td>Adresse Email</td>
                <td><input type="email"/></td>
              </tr>
              <tr>
                <td>Contact 1</td>
                <td><input type="text" minLength="10" maxLength="10" pattern="&#91;0-9&#93;*" placeholder="0700000000" title="Entrez des nombres" required/></td>
              </tr>
              <tr>
                <td>Contact 2</td>
                <td><input type="text"/></td>
              </tr>
            </table>
            <table>
              <tr>
                <td>Bien</td>
                <td><input type="text" value={publications[bienId].type} disabled/></td>
              </tr>
              <tr>
                <td>Pièces</td>
                <td><input type="text" value={publications[bienId].pieces} disabled/></td>
              </tr>
              <tr>
                <td>Localisation</td>
                <td><input type="text" value={publications[bienId].lieu} disabled/></td>
              </tr>
              <tr>
                <td>Statut</td>
                <td><input type="text" value={publications[bienId].statut} disabled/></td>
              </tr>
              <tr>
                <td>Prix</td>
                <td><input type="text" value={publications[bienId].prix} disabled/></td>
              </tr>
              <tr>
                <td>Choisir une date de visite</td>
                <td>
                  <select name="date">
                    <option value="01-01-2023">01-01-2023</option>
                    <option value="01-01-2023">02-01-2023</option>
                    <option value="01-01-2023">03-01-2023</option>
                    <option value="01-01-2023">04-01-2023</option>
                    <option value="01-01-2023">05-01-2023</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Choisir une Heure de visite</td>
                <td>
                  <select name="heure">
                    <option value="7h00">7:00</option>
                    <option value="10h00">10:00</option>
                    <option value="10h00">13:30</option>
                    <option value="15h00">15:00</option>
                    <option value="17h00">17:00</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>Message</td>
                <td><textarea name="msg" cols="38" rows="7" style={{resize: "none"}}></textarea></td>
              </tr>
              <p style={{color: "red", fontFamily: 'Times New Roman'}}><i><br />Prévoir la somme de 5 000 Fcfa pour la visite<br /><br /><br /></i></p>
              <input type="submit" value="Envoyer"/>
            </table>
            </div>
          </form>
      </section>
      <Footer/>
    </div>
  )
}
