import React, { useState } from 'react'
import './acceuil.css'
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../../composants/navbar/Navbar'
import Footer from '../../composants/footer/Footer'
import Publication from '../../composants/publication/Publication'
import publications from '../../publicationdata.js'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

export default function Acceuil() {
  const [recherche, setRecherche] = useState("")

  const filtrer = publications.filter((pub)=>{
    return (pub.type.toLowerCase().includes(recherche.toLowerCase()) || pub.lieu.toLowerCase().includes(recherche.toLowerCase()))
  })
  const [isScrolled, setIsscrolled] = useState(false);
    window.onscroll = () => {
        setIsscrolled(window.pageYOffset === 0 ? false : true);
        return () => (window.onscroll = null);
    }

  return (
    <div className='acceuil' id='acceuil'>
      <Navbar/>
      <section className='articles'>
      <div className='recherche'>
            <h2>Vous recherchez ?</h2>
            <form>
                <div className='barre'>
                    <input type="text" placeholder='Recherche...' value={recherche} onSubmit={(e)=>{e.preventDefault()}} onChange={(e)=>{setRecherche(e.target.value)}}  />
                    <SearchIcon style={{opacity: "0.5", fontSize: "25px"}}/>
                </div>
                <hr />
                <div>
                    <label htmlFor="ville">Ville</label>
                    <select name="ville" id="ville">
                        <option value="none">_______</option>
                        <option value="abengourou">Abengourou</option>
                        <option value="assinie">Assinie</option>
                        <option value="bingerville">Bingerville</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="quartier">Quartier</label>
                    <select name="quartier" id="quartier">
                        <option value="none">_______</option>
                        <option value="hkb">HKB</option>
                        <option value="relais">Relais</option>
                        <option value="caffetou">Caffetou</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="type">Type de propriété</label>
                    <select name="type" id="type">
                        <option value="none">_______</option>
                        <option value="Maison">Maison</option>
                        <option value="Studio">Studio</option>
                        <option value="Terrain">Terrain</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="status">Statut</label>
                    <select name="status" id="status">
                        <option value="none">_______</option>
                        <option value="vente">En vente</option>
                        <option value="louer">A louer</option>
                    </select>
                </div>
            </form>
        </div>        
        <div className="publications">
          {filtrer.length === 0 && <p className='aucun'>Aucun articles disponibles pour l'instant</p>}
          {filtrer.length > 0 && (
            filtrer.map((pub)=>{return <div key={pub.id}><Publication id={pub.id} type={pub.type} lieu={pub.lieu} date={pub.date} image={pub.image} statut={pub.statut}/></div>})
          )}
        </div>
      </section>
      <a className={isScrolled ? "monter": "rien"} href="#acceuil">
        <KeyboardDoubleArrowUpIcon/>
      </a>
      <Footer/>
    </div>
  )
}
